* {
    box-sizing: border-box;
}

body{
    margin: 0;
    background: linear-gradient(320deg, #eb92be, #ffef78, #63c9b4);

}


.p{
    text-align: left;
}

.img{
    max-width: max-content;
    height: auto;
}


  @media only screen and (min-width: 768px) {
    h1{
    max-width: 100%;
    white-space: nowrap;
    text-align: center;
    word-break: keep-all;
    font-size: 2.75vw;
    }
    .großeliste{
      font-size: 2vw;
    }
    .Fließtext{
        text-align: justify;
        font-size: 2vw;
    }
    .video{
      width: 50vw;
      height: 28.5vw;
    }
    .linksmitte{
      padding-left:24.5%;
      text-align: left;
    }
    .mail{
      padding-left:36%;
      font-size: 2vw;
      text-align: left;
      color:darkblue
    }
    .youtube{
      padding-left:36%;
      font-size: 2vw;
      text-align: left;
      color:rgb(199, 13, 56)
    }
    .spotifyabout{
      padding-left:36%;
      font-size: 2vw;
      text-align: left;
      color:rgb(30, 215, 96)
    }
    .mittigblocksatz{
      padding-left:24.5%;
      padding-right:24.5%;
      text-align: justify;
      font-size: 1.5vw;
     }
     .Bildunterschrift{
      font-size: 1.25vw;
   }
   .download{
    display: inline;
    text-align: left;
  }
  .onlymobile{
    display: none;
  }
  .onlyPC{
    display: block;
  }
  .spotify{
    width: 50vw;
    height: 10vw;
  }
  }
  @media only screen and (max-width: 768px) {
    h1{
      text-align: center;
      font-size: 4.5vw;
    }
    h2{
      text-align: center;
      font-size: 4vw;
    }
    h3{
      text-align: center;
      font-size: 2.5vw;
    }
    .großeliste{
      text-align: left;
      font-size: 4.3vw;
    }
    .Fließtext{
        text-align: justify;
        font-size: 4vw;
    }
    .linksmitte{
      padding-left:0%;
      text-align: left;
      font-size: 2.7vw;
    }
    .mail{
      padding-left:15%;
      font-size: 3vw;
      text-align: left;
      color:darkblue
    }
    .youtube{
      padding-left:15%;
      font-size: 3vw;
      text-align: left;
      color:rgb(199, 13, 56)
   }
    .spotifyabout{
    padding-left:15%;
    font-size: 3vw;
    text-align: left;
    color:rgb(30, 215, 96)
   }
   .mittigblocksatz{
    padding-left:9%;
    padding-right:9%;
    text-align: justify;
    font-size: 2.7vw;
   }
   .Bildunterschrift{
    font-size: 2.5vw;
  }
  .download{
  display: inline;
  text-align: left;
  background-color: gold;
  }
  .onlymobile{
    display: block;
  }
  .onlyPC{
    display: none;
  }
}

  .full-width-image {
    width: 100%;
  }

  a{
    color: inherit;
    text-decoration: 0;
    height: 100%;
    display: flex;
    align-items: center;
  }

  a:hover{
    color:rgba(0, 128, 255, 0.708);
  }

  .links{
    text-align: left;
  }

  ul {
    list-style-type: circle;
  }

  

  

  