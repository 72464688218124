@media only screen and (min-width: 768px) {
.image-button-left {
    display: inline-block;
    width: 28%;
    height: 28%;
    text-align: left;
    margin-right: 4%;
    margin-bottom: 3.5%;
}

.image-button-left img{
    display: inline-block;
    width: 100%;
    height: 100%;
}

.image-button-center {
    display: inline-block;
    width: 28%;
    height: 28%;
    text-align: center;
    margin-bottom: 3.5%;
}

.image-button-center img{
    display: inline-block;
    width: 100%;
    height: auto;
}

.image-button-right {
    display: inline-block;
    width: 28%;
    height: 28%;
    text-align: right;
    margin-left: 4%;
    margin-bottom: 3.5%;
}

.image-button-right img{
    display: inline-block;
    width: 100%;
    height: 100%;
}

.Bildunterschrift{
    text-align: center;
}
}

@media only screen and (max-width: 768px) {
    a {
        display: inline-block;
        width: 90%;
        font-size: 0.57em;
        margin-bottom: 7.5%;
    }
    img {
        width: 100%;
    }
    .Bildunterschrift{
        text-align: center;
    }
}