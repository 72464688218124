* {
    box-sizing: border-box;
}

body{
    margin: 0;
    font-family: Georgia, 'Times New Roman', Times, serif;
}

.p {
    text-align: left;
}

.container {
    margin: 1rem;
    text-align: center;
}

@media only screen and (min-width: 768px) {
    .nav{
        font-size: 80%;
        background-color: #333;
        color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
        padding: 0 1rem;
        max-width: 100%;
        overflow: hidden;
    }
    
    .site-title{
        font-size: 2rem;
    }
    
    .nav ul{
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        gap: 1rem;
    }
    
    .nav a{
        color: inherit;
        text-decoration: 0;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0.25rem;
    } 
}

@media only screen and (max-width: 768px) {
    .nav{
        font-size: 140%;
        background-color: #333;
        color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 0.5rem;
        padding: 0 0.5rem;
        width: 100%;
        overflow: hidden;
    
    }
    
    .site-title{
        font-size: 1.5rem;
    }
    
    .nav ul{
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        gap: 0.5rem;
    }
    
    .nav a{
        color: inherit;
        text-decoration: 0;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0.25rem;
    }
}

.nav li:active {
    background-color: #555;
}

.nav li:hover {
    color:rgba(0, 128, 255, 0.708);
}


